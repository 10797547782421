<template>
  <v-app class="primary">
    <v-main>
      <router-view/>
    </v-main>
    <v-snackbar :top="snackbar.top" v-model="snackbar.open">
      {{ snackbar.text }}
      <template v-slot:action="{attrs}">
        <v-btn text :color="snackbar.color" v-bind="attrs" @click="snackbar.open = false">OK</v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
  export default {
    data: () => ({
      snackbar: {
        color: 'green',
        open: false,
        text: null,
        top: false,
      },
    }),
    mounted() {
      this.$root.$on('snackbar', (payload) => {
        this.snackbar = {
          open: true, color: 'green', ...payload
        }
      })
    },
  }
</script>
